<template>
  <section>
    <quick-export-gobd
      v-permissions="{
        scopes: ['reports_exports_manager:financial:gobd']
      }"
    />

    <quick-export-safes-logs
      v-permissions="{
        scopes: ['reports_exports_manager:financial:safe_logs']
      }"
      :safes="resources.safes"
    />
  </section>
</template>

<script>
import QuickExportGobd from './exports/gobd'
import QuickExportSafesLogs from './exports/safes-logs'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  name: 'QuickExports',
  components: {
    QuickExportGobd,
    QuickExportSafesLogs
  },
  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)

    return { featureConfig }
  },
  data() {
    return {
      activeName: this.$t(
        'pages.reports.exports.overview.quick.tabs.financial.title'
      ),
      resources: {}
    }
  },
  created() {
    this.fetchResources()
  },
  methods: {
    async fetchResources() {
      this.resources = await this.$resourceFetch('safes')
    }
  }
}
</script>
