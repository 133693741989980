<template>
  <span class="ml-2">
    <!-- Safe Logs -->
    <el-button type="primary" @click="handleModal()">
      {{
        $t(
          'pages.reports.exports.overview.quick.tabs.financial.safes_logs.title'
        )
      }}
    </el-button>

    <!-- Modal -->
    <th-modal
      :title="
        $t(
          'pages.reports.exports.overview.quick.tabs.financial.safes_logs.title'
        )
      "
      width="650px"
      name="safe-logs"
    >
      <el-form :model="form">
        <!-- Date range -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.date_range.label'
            )
          "
        >
          <date-range-picker
            v-model="localDateRange"
            :locale="$i18n.locale"
            @update:modelValue="handleChange"
          />
        </el-form-item>

        <!-- Type -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.transfer_type.label'
            )
          "
        >
          <el-select v-model="form.transferType" clearable multiple>
            <el-option
              v-for="(type, index) in transferTypes"
              :key="index"
              :label="type.label"
              :value="type.value"
            />
          </el-select>
        </el-form-item>

        <!-- From (source) -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.source.label'
            )
          "
        >
          <el-select
            v-model="form.source"
            clearable
            multiple
            :disabled="!!form.source_or_destination.length"
          >
            <el-option
              v-for="(safe, index) in safes"
              :key="index"
              :label="safe.name"
              :value="safe.id"
            />
          </el-select>
        </el-form-item>

        <!-- To (destination) -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.destination.label'
            )
          "
        >
          <el-select
            v-model="form.destination"
            clearable
            multiple
            :disabled="!!form.source_or_destination.length"
          >
            <el-option
              v-for="(safe, index) in safes"
              :key="index"
              :label="safe.name"
              :value="safe.id"
            />
          </el-select>
        </el-form-item>

        <!-- From or to (source or destination) -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.source_or_destination.label'
            )
          "
        >
          <div class="flex items-center">
            <el-select
              v-model="form.source_or_destination"
              clearable
              multiple
              :disabled="!!form.source.length || !!form.destination.length"
            >
              <el-option
                v-for="(safe, index) in safes"
                :key="index"
                :label="safe.name"
                :value="safe.id"
              />
            </el-select>
            <th-popover
              class="ml-2"
              :text="
                $t(
                  'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.source_or_destination.popover'
                )
              "
              placement="bottom"
            />
          </div>
        </el-form-item>

        <!-- Curency -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.currency.label'
            )
          "
          required
        >
          <th-currency-select v-model="form.currency" />
        </el-form-item>

        <!-- Amount range start -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.transfer_value_range_start.label'
            )
          "
        >
          <th-currency-input
            v-model="form.transfer_value_range_start"
            :currency="form.currency"
          />
        </el-form-item>

        <!-- Amount range end -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.form.transfer_value_range_end.label'
            )
          "
        >
          <th-currency-input
            v-model="form.transfer_value_range_end"
            :currency="form.currency"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <!-- Cancel -->
        <el-button @click="handleModal(false)">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>

        <!-- Download -->
        <el-button
          v-loading="downloadIsLoading"
          type="primary"
          @click="download"
        >
          {{ $t('common.interactions.buttons.download') }}
        </el-button>
      </template>
    </th-modal>
  </span>
</template>

<script>
import DateRangePicker from '@/components/date-picker/range'
import { useExportsStore } from '@/store/exports'

function genInitialForm() {
  return {
    dateRange: {},
    transferType: [],
    source: [],
    destination: [],
    source_or_destination: [],
    transfer_value_range_start: null,
    transfer_value_range_end: null,
    currency: 'EUR'
  }
}

export default {
  name: 'QuickExportsFinancialSafesLogs',
  components: {
    DateRangePicker
  },
  props: {
    safes: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      localDateRange: {},
      form: genInitialForm(),
      downloadIsLoading: false,
      locale: this.$i18n.locale
    }
  },
  computed: {
    transferTypes() {
      return [
        {
          value: 'safe_to_safe',
          label: this.$t(
            'pages.reports.exports.overview.quick.tabs.financial.safes_logs.transfer_types.safe_to_safe'
          )
        },
        {
          value: 'safe_to_bank',
          label: this.$t(
            'pages.reports.exports.overview.quick.tabs.financial.safes_logs.transfer_types.safe_to_bank'
          )
        },
        {
          value: 'safe_to_expense',
          label: this.$t(
            'pages.reports.exports.overview.quick.tabs.financial.safes_logs.transfer_types.safe_to_expense'
          )
        },
        {
          value: 'pos_to_safe',
          label: this.$t(
            'pages.reports.exports.overview.quick.tabs.financial.safes_logs.transfer_types.pos_to_safe'
          )
        }
      ]
    }
  },
  methods: {
    clearForm() {
      this.form = genInitialForm()
    },
    handleModal(val = true) {
      if (val) return this.$thModal.show('safe-logs')
      this.clearForm() // Always clear the form when the modal is closed
      this.$thModal.hide('safe-logs')
    },
    async handleChange(dateObject) {
      this.form.dateRange = {
        start: dateObject.start,
        end: dateObject.end
      }
    },
    async download() {
      const query = {
        format: 'csv'
      }

      if (this.form.dateRange.start) query.start = this.form.dateRange.start

      if (this.form.dateRange.end) query.end = this.form.dateRange.end

      if (this.form.transferType && this.form.transferType.length) {
        query.transfer_type = this.form.transferType
      }

      if (this.form.source && this.form.source.length)
        query.source = this.form.source

      if (this.form.destination && this.form.destination.length)
        query.destination = this.form.destination

      if (
        this.form.source_or_destination &&
        this.form.source_or_destination.length
      ) {
        query.source_or_destination = this.form.source_or_destination
      }

      const hasAmountStart = Number.isFinite(
        this.form.transfer_value_range_start
      )
      const hasAmountEnd = Number.isFinite(this.form.transfer_value_range_end)

      query.currency = this.form.currency

      if (hasAmountStart) {
        query.transfer_value_range_start = this.form.transfer_value_range_start
      }

      if (hasAmountEnd) {
        query.transfer_value_range_end = this.form.transfer_value_range_end
      }

      try {
        const { safesLogBookV1 = [] } = await this.$resourceFetch({
          resource: 'safesLogBookV1',
          query
        })

        const exportId = safesLogBookV1[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey:
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.title',
            date: new Date(),
            action: {
              entity: 'safesLogBookV1',
              query
            }
          }
        })
        this.handleModal(false)
      } catch (err) {
        const errorMessage = this.$t(
          'pages.reports.exports.overview.quick.tabs.financial.safes_logs.errors.download_failed'
        )
        this.$logException(err, { message: errorMessage })
      }
    }
  }
}
</script>
