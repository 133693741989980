<template>
  <span>
    <el-button type="primary" @click="handleModal()">
      {{ $t('pages.reports.exports.overview.quick.tabs.financial.gobd.title') }}
    </el-button>

    <th-modal
      :title="
        $t('pages.reports.exports.overview.quick.tabs.financial.gobd.title')
      "
      width="650px"
      name="tax-audit-export"
    >
      <el-form :model="form">
        <!-- Time range -->
        <el-form-item
          :label="
            $t(
              'pages.reports.exports.overview.quick.tabs.financial.gobd.form.range.label'
            )
          "
        >
          <date-range-picker
            v-model="localDateRange"
            :locale="$i18n.locale"
            placement="right"
            @update:modelValue="handleChange"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <!-- Cancel -->
        <el-button @click="handleModal(false)">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>

        <!-- Download -->
        <el-button
          v-loading="downloadIsLoading"
          type="primary"
          @click="download"
        >
          {{ $t('common.interactions.buttons.download') }}
        </el-button>
      </template>
    </th-modal>
  </span>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import DateRangePicker from '@/components/date-picker/range'
import { useExportsStore } from '@/store/exports'

export default {
  name: 'QuickExportsFinancialGobd',
  components: {
    DateRangePicker
  },
  data() {
    return {
      localDateRange: {},
      form: {
        email: null,
        dateRange: {}
      },
      downloadIsLoading: false
    }
  },
  methods: {
    handleModal(val = true) {
      if (val) return this.$thModal.show('tax-audit-export')
      this.$thModal.hide('tax-audit-export')
    },
    async handleChange(dateObject) {
      this.form.dateRange = {
        start: dateObject.start,
        end: dateObject.end
      }
    },
    async download() {
      const errorMessage = this.$t(
        'pages.reports.exports.overview.quick.tabs.financial.gobd.errors.download_failed'
      )

      const query = {
        start: this.form.dateRange.start,
        end: this.form.dateRange.end
      }

      try {
        this.downloadIsLoading = true
        const { data } = await th.exportsV1().gobd(query)
        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        this.handleModal(false)

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey:
              'pages.reports.exports.overview.quick.tabs.financial.gobd.title',
            date: new Date(),
            action: {
              entity: 'exportsV1',
              handler: 'gobd',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.downloadIsLoading = false
      }
    }
  }
}
</script>
