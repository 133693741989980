<template>
  <th-page-wrapper>
    <el-row :gutter="20">
      <el-col :xl="10" :lg="16">
        <th-wrapper
          class="m-8"
          :title="$t('pages.reports.exports.overview.overview')"
          :subtitle="$t('pages.reports.exports.overview.description')"
        >
          <p class="text-sm mt-0">
            {{ $t('pages.reports.exports.overview.how_to.description') }}
          </p>
          <quick-exports />
        </th-wrapper>
      </el-col>
    </el-row>
  </th-page-wrapper>
</template>

<script>
import QuickExports from './components/quick'

export default {
  name: 'Overview',
  metaInfo() {
    return {
      title: this.$t('pages.reports.exports.exports')
    }
  },
  components: {
    QuickExports
  }
}
</script>
